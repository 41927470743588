
import { Component, Vue, Watch } from 'vue-property-decorator';
import Axios from 'axios';
import { TabIds } from '@/views/Home.vue';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import { ProjectKey } from '@/types/projectKey';

@Component
export default class DamTable extends Vue {
  footerProps = { 'items-per-page-text': '行/1ページ:', 'items-per-page-options': [20, 50, 100, -1] };

  itemPerPage = -1;

  sysInfoStore = useSysInfoStore();

  get isMobile() {
    return this['$store'].getters.isMobile;
  }

  get result() {
    return this['$store'].getters.result;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get latestTargetTime() {
    return this['$store'].getters.latestTargetTime;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  get rerender() {
    return this['$store'].getters.rerender;
  }

  get headers() {
    if (!this.type) return [];
    if (Number(this.type) === 7) {
      return this.headers7;
    }
    return this.headers4;
  }

  get formDownloadButton() {
    return this.sysInfoStore.formDownloadButton;
  }

  get isShowDownloadStatementOfReasonsButton() {
    if (!this.formDownloadButton || !this.formDownloadButton.enable) {
      return false;
    }
    if (!this.formDownloadButton.target_stations.includes(this.stationId)) {
      return false;
    }
    return true;
  }

  get displayItems() {
    if (this.displayScale >= 1) {
      let count = 0;
      if (this.type === 7) {
        const items: {
          datetime: string,
          // eslint-disable-next-line camelcase
          data_datetime: string,
          R: any,
          Ruika: any,
          wlevel: any,
          // eslint-disable-next-line camelcase
          Qin_all: any,
          // eslint-disable-next-line camelcase
          Qout_all: any,
        }[] = [];
        let RList: any[] = [];
        let QinAllList: any[] = [];
        let QoutAllList: any[] = [];
        /**
         * 1時間平均を表示
         * 累加雨量と貯水位は平均をしない。当該時刻の10分値を表示する。
         */
        for (let i = 0; i < this.items.length; i += 1) {
          count += 1;
          RList.push(this.items[i].R);
          QinAllList.push(this.items[i].Qin_all);
          QoutAllList.push(this.items[i].Qout_all);
          if (this.displayScale === 1) {
            if (this.items[i].datetime.slice(9, 11) === this.targetTime.slice(10, 12)) {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.getAverage(RList),
                Ruika: this.items[i].Ruika,
                wlevel: this.items[i].wlevel,
                Qin_all: this.getAverage(QinAllList),
                Qout_all: this.getAverage(QoutAllList),
              });
              count = 0;
              RList = [];
              QinAllList = [];
              QoutAllList = [];
            }
          } else if (this.displayScale === 2) {
            if (this.items[i].datetime.slice(9, 11) === '00') {
              if (count === 6) {
                items.push({
                  datetime: this.items[i].datetime,
                  data_datetime: this.items[i].data_datetime,
                  R: this.getAverage(RList),
                  Ruika: this.items[i].Ruika,
                  wlevel: this.items[i].wlevel,
                  Qin_all: this.getAverage(QinAllList),
                  Qout_all: this.getAverage(QoutAllList),
                });
              } else {
                items.push({
                  datetime: this.items[i].datetime,
                  data_datetime: this.items[i].data_datetime,
                  R: this.items[i].R,
                  Ruika: this.items[i].Ruika,
                  wlevel: this.items[i].wlevel,
                  Qin_all: this.items[i].Qin_all,
                  Qout_all: this.items[i].Qout_all,
                });
              }
              count = 0;
              RList = [];
              QinAllList = [];
              QoutAllList = [];
            }
          }
        }
        return items.map(((item) => {
          Object.keys(item).forEach((key) => {
            // eslint-disable-next-line no-param-reassign
            item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
          });
          return item;
        }));
      } if (this.type === 4) {
        const items: {
          datetime: string,
          // eslint-disable-next-line camelcase
          data_datetime: string,
          R: any,
          Ruika: any,
          wlevel: any,
          flow: any,
        }[] = [];
        let RList: any[] = [];
        let wlevelList: any[] = [];
        let flowList: any[] = [];
        /**
         * 1時間平均を表示
         * 累加雨量は平均をしない。当該時刻の10分値を表示する。
         */
        for (let i = 0; i < this.items.length; i += 1) {
          count += 1;
          RList.push(this.items[i].R);
          wlevelList.push(this.items[i].wlevel);
          flowList.push(this.items[i].flow);
          if (this.displayScale === 1) {
            if (this.items[i].datetime.slice(9, 11) === this.targetTime.slice(10, 12)) {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.getAverage(RList),
                Ruika: this.items[i].Ruika,
                wlevel: this.getAverage(wlevelList),
                flow: this.getAverage(flowList),
              });
              count = 0;
              RList = [];
              wlevelList = [];
              flowList = [];
            }
          } else if (this.displayScale === 2) {
            if (this.items[i].datetime.slice(9, 11) === '00') {
              if (count === 6) {
                items.push({
                  datetime: this.items[i].datetime,
                  data_datetime: this.items[i].data_datetime,
                  R: this.getAverage(RList),
                  Ruika: this.items[i].Ruika,
                  wlevel: this.getAverage(wlevelList),
                  flow: this.getAverage(flowList),
                });
              } else {
                items.push({
                  datetime: this.items[i].datetime,
                  data_datetime: this.items[i].data_datetime,
                  R: this.items[i].R,
                  Ruika: this.items[i].Ruika,
                  wlevel: this.items[i].wlevel,
                  flow: this.items[i].flow,
                });
              }
              count = 0;
              RList = [];
              wlevelList = [];
              flowList = [];
            }
          }
        }
        return items.map(((item) => {
          Object.keys(item).forEach((key) => {
            // eslint-disable-next-line no-param-reassign
            item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
          });
          return item;
        }));
      }
    }
    return JSON.parse(JSON.stringify(this.items)).map(((item) => {
      Object.keys(item).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
      });
      return item;
    }));
  }

  get type() {
    return this['$store'].getters.type;
  }

  getDisplayData({ value, decimalPlaces, precision }: {
    value: string | number,
    decimalPlaces: number,
    precision?: number,
  }) {
    const parsedNum = Number(value);
    if (Number.isNaN(parsedNum)) {
      return '-';
    }

    if (!precision) {
      return parsedNum.toFixed(decimalPlaces);
    }
    const multiplier = 10 ** precision;
    const roundedNum = Math.round(parsedNum * multiplier) / multiplier;
    return roundedNum.toFixed(decimalPlaces);
  }

  getAverage(list) {
    const count = list.reduce((a, b) => a + (b <= -999 ? 0 : 1), 0);
    return count > 0 ? list.reduce((a, b) => a + (b <= -999 ? 0 : b), 0) / count : -999;
  }

  displayScale = 1;

  headers4 = [
    {
      text: '時刻', value: 'datetime', class: 'px-1', align: 'center', width: '80',
    },
    {
      text: '降雨強度(mm/h)', value: 'R', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '累加雨量(mm)', value: 'Ruika', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '河川水位(m)', value: 'wlevel', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '河川流量(㎥/s)', value: 'flow', class: 'px-1', sortable: false, align: 'right',
    },
  ];

  headers7 = [
    {
      text: '時刻', value: 'datetime', class: 'px-1', align: 'center', width: '80',
    },
    {
      text: '降雨強度(mm/h)', value: 'R', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '累加雨量(mm)', value: 'Ruika', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '貯水位(EL.m)', value: 'wlevel', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '流入量(㎥/s)', value: 'Qin_all', class: 'px-1', sortable: false, align: 'right',
    },
    {
      text: '放流量(㎥/s)', value: 'Qout_all', class: 'px-1', sortable: false, align: 'right',
    },
  ];

  items: any[] = [];

  isDownloading = {
    result: false,
    allPoint: false,
    statementOfReasons: false,
  }

  async downloadResult() {
    this.isDownloading.result = true;
    const result = await Axios.get(`/api/excel/${this.basinId}/${this.stationId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`);
    if (result.status === 200) {
      window.open(result.data.download_url);
    }
    this.isDownloading.result = false;
  }

  /**
   * 2024/10/24
   * ただし書き操作移行理由書のダウンロード
   * https://redmine.riskma.jp/issues/354
   */
  async downloadStatementOfReasons() {
    this.isDownloading.statementOfReasons = true;
    const result = await Axios.get(`/api/downloadStatementOfReasons/${this.basinId}/${this.stationId}`, {
      params: {
        tgttime: this.targetTime,
        resultFile: this.formDownloadButton?.result_file ?? '',
        s3Path: this.formDownloadButton?.s3_path ?? '',
      },
    });
    if (result.status === 200) {
      window.open(result.data.download_url);
    }
    this.isDownloading.statementOfReasons = false;
  }

  async downloadAllPoint() {
    const filteredStationsJsonStr = localStorage.getItem('filteredStations');
    const filteredStations = filteredStationsJsonStr ? JSON.parse(filteredStationsJsonStr) : [];
    const chunkedArray: any = [];

    // 15個ずつのチャンクに分ける
    for (let i = 0; i < filteredStations.length; i += 15) {
      const chunk = filteredStations.slice(i, i + 15);
      chunkedArray.push(chunk);
    }

    // サーバーレス対応している場合
    this.isDownloading.allPoint = true;

    if (chunkedArray.length > 1) {
      alert(`ダウンロードする地点の数が多いため、${chunkedArray.length}分割でダウンロードされます。ご了承ください。`);
    }

    // 各チャンクを処理するための関数
    const downloadChunk = async (chunk: any[], part: number) => {
      const result = await Axios.post(`/api/allExcel/${this.basinId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`, {
        stations: chunk,
        part,
      });
      if (result.status === 200) {
        window.open(result.data.download_url);
      }
    };

    // すべてのチャンクをダウンロード
    for (let part = 0; part < chunkedArray.length; part += 1) {
      // eslint-disable-next-line no-await-in-loop
      await downloadChunk(chunkedArray[part], part + 1);
    }

    // これ以上はダウンロードできない
    this.isDownloading.allPoint = false;
  }

  show10MinCopyButton() {
    const sysInfoStore = useSysInfoStore();
    if (sysInfoStore.projectKey === ProjectKey.Fukuoka) {
      return true;
    }

    return false;
  }

  copyResultData() {
    let copyString = 'datetime\twlevel\tpreStage\tQin_all\tQout_all\tR\tRuika\n';
    this.result.sim_result.forEach((result) => {
      copyString += result.datetime;
      copyString += `\t${result.wlevel}`;
      copyString += `\t${result.preStage}`;
      copyString += `\t${result.Qin_all}`;
      copyString += `\t${result.Qout_all}`;
      copyString += `\t${result.R}`;
      copyString += `\t${result.Ruika}\n`;
    });

    // eslint-disable-next-line no-tabs
    navigator.clipboard.writeText(copyString)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('10分間隔の予測結果をコピーしました。');
      });
  }

  getBackgroundColor(datetime) {
    const targetData = new Date(datetime.replace(/-/g, '/'));
    const year = this.targetTime.slice(0, 4);
    const month = this.targetTime.slice(4, 6);
    const day = this.targetTime.slice(6, 8);
    const timeH = this.targetTime.slice(8, 10);
    const timeM = this.targetTime.slice(10, 12);
    const convertedTargetDate = new Date(`${year}/${month}/${day} ${timeH}:${timeM}`);
    if (targetData.getTime() === convertedTargetDate.getTime()) {
      return '#f7ef79';
    }
    if (targetData.getTime() <= convertedTargetDate.getTime()) {
      return '#e2f4e2';
    }
    return '#c9e4ec';
  }

  @Watch('result')
  @Watch('rerender')
  async updateResult() {
    this.items = this.result.sim_result.map((result) => {
      const month = result.datetime.slice(5, 7);
      const [day, time] = result.datetime.slice(8, 16).split(' ');
      return {
        ...result,
        data_datetime: result.datetime,
        datetime: `${month}/${day} ${time}`,
      };
    });
  }
}
